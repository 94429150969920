

























import Vue from 'vue'
import Login from '@/components/LoginForm.vue'
import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    Login,
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    // If the user is already logged in, there is no need to display this page
    if (this.user) {
      this.$router.push({
        name: 'dashboard',
      })
    }
  },
})
